import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Expand() {
  const [t] = useTranslation();

  const handleExpand = () => {};
  return (
    <div>
      <Button variant="text" onClick={handleExpand} color="success">
        {t("fathom.expand")}
      </Button>
    </div>
  );
}
