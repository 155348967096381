import React from "react";
import styles from "./LayerTitle.module.scss";
import Svg from "components/svg/svg";
import { ReactComponent as IconPremium } from "assets/icon_premium.svg";
import { AnalysisItem, LegendItem } from "helpers/models";
import { useTranslation } from "react-i18next";

interface Props {
  legendItem: LegendItem;
  analysisItem: AnalysisItem;
}

export default function LayerTitle({
  legendItem,
  analysisItem,
}: Readonly<Props>) {
  const [t] = useTranslation();
  return (
    <div className={styles.div}>
      <div>{Svg({ icon: legendItem.layerId })()}</div>
      <div className={styles.label}>
        <div className="truncate max-w-100 ml-2 text-sm">
          {t("layers.item." + legendItem.title)}
        </div>
      </div>
      <IconPremium className={styles.icon} />
    </div>
  );
}
