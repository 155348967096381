import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useState } from "react";
import styles from "./events.module.scss";

export default function Events() {
  const [loading, setLoading] = useState(0);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setLoading(0);
  }, []);

  const handleClick = () => {
    setLoading(1);
    setTimeout(() => {
      setLoading(2);
    }, 2000);
  };

  const handleChangeExpand = () => {
    setExpanded(!expanded);
  };

  const showEvents = (event: any): void => {
    console.log(event)
  };

  return (
    <div className={styles.div}>
      <div className={styles.title}>Events</div>

      <Accordion
        expanded={expanded}
        onChange={handleChangeExpand}
        sx={{
          boxShadow: "none",
          "&::before": {
            display: "none",
          },
          padding: "0 11px 0 0",
          marginTop: "20px",
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            loading !== 2 ? (
              <div style={{ width: "20px" }}></div>
            ) : (
              <div className="material-icons">keyboard_arrow_right</div>
            )
          }
          sx={{
            minHeight: "30px !important",
            height: "30px",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
          }}
        >
          <div className={styles.body}>
            <div className={styles.left}>Recent</div>
            <div className={styles.right}>
              {loading === 0 && <div onClick={handleClick}>LOAD EVENTS</div>}
              {loading === 1 && (
                <div className={styles.loading}>
                  <div className="loading loading-spinner loading-xs"></div>
                  <div className={styles.text}>Loading</div>
                </div>
              )}
              {loading === 2 && (
                <div className={styles.resp}>2 type of Events</div>
              )}
            </div>
          </div>
        </AccordionSummary>
        {loading === 2 && (
          <AccordionDetails
            sx={{
              paddingLeft: "50px",
              width: "100%",
            }}
          >
            <div>
              <div className={styles.boldtext}>Flood</div>
              <div className={styles.boldtext}>Rainfall</div>
              <div
                className={styles.boldtext}
                style={{
                  color: "var(--green)",
                  cursor: "pointer",
                  marginTop: "7px",
                }}
                onClick={showEvents}
              >
                Show Events
              </div>
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}
