function pint(value: string, title: string): boolean {
  const valueRep = value.replace(" years", "");
  const valueInt = parseInt(valueRep);
  if (title.includes("≥")) {
    const minYear = parseInt(title.replace("≥", "").replace(" years", ""));
    if (valueInt >= minYear) {
      return true;
    }
  } else if (title.includes("≤")) {
    const maxYear = parseInt(title.replace("≤", "").replace(" years", ""));
    if (valueInt <= maxYear) {
      return true;
    }
  } else {
    const exactYear = parseInt(title.replace(" years", ""));
    if (valueInt === exactYear) {
      return true;
    }
  }
  return false;
}
export default pint;
