import { AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LayersSvg } from "../../../assets/icon_layers.svg";
import Expander from "../expander/expander";
import styles from "./layers.module.scss";

function Layers() {
  const [context] = useContext(AppContext);
  const [t] = useTranslation();

  const getLayersCount = (): number => {
    if (context.layerLegend) {
      return context.layerLegend.filter((item, index) => {
        return item.layerInfo.checked
      }).length
    }
    return 0;
  };

  return getLayersCount() > 0 ? (
    <div className={styles.layers}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <LayersSvg />
        </div>
        <div>{t("layers.title")}</div>
        <div className={styles.count}>
          {getLayersCount()}{" "}
          {getLayersCount() === 1 ? t("layers.singular") : t("layers.title")}
        </div>
      </div>
      <Expander title={t("layers.items.flood.fluvial")} result="Outside">
        <div className={styles.content}>
          <img
            title="layer_info_flood"
            alt="layer_info_flood"
            src="/layer_info_flood.png"
          />
        </div>
      </Expander>
      <Expander
        title={t("layers.items.earthquake.soil")}
        result={
          <div className="flex items-center">
            <div>{t("single.risk")}</div>
            <div className={styles.risk}></div>
          </div>
        }
      >
        <div className={styles.content}>
          <img
            title="layer_info_earthquake"
            alt="layer_info_earthquake"
            src="/layer_info_earthquake.png"
          />
        </div>
      </Expander>
    </div>
  ) : null;
}

export default Layers;
