import { ReactComponent as IconPremium } from "assets/icon_premium.svg";
import styles from "./RiskScore.module.scss";
import { AnalysisItem, LegendItem } from "helpers/models";
import { useTranslation } from "react-i18next";

interface Props {
  legendItem: LegendItem;
  analysisItem: AnalysisItem;
}

export default function RiskScore({
  legendItem,
  analysisItem,
}: Readonly<Props>) {
  const [t] = useTranslation();
  
  return (
    <div className={styles.div}>
      <div className={styles.section}>
        <div className={styles.label}>{t("fathom.RiskScore")}</div>

        <span className={`material-icons ${styles.info}`}>info</span>
        <IconPremium className={styles.icon} />
      </div>

      <div className={styles.score}>
        {t("fathom.Relative Score")} <strong>{analysisItem.relativeRisk}</strong>
      </div>
      <div className={styles.score}>
        {t("fathom.Category Score")} <strong>{analysisItem.riskCategory}</strong>
      </div>
    </div>
  );
}
