import { useState } from "react";
import styles from "./expander.module.scss";

interface Props {
  title: string;
  result: React.ReactNode;
  children: React.ReactNode;
}

function Expander(props: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className={styles.expander}>
      <div className={styles.header} onClick={() => setExpanded(!expanded)}>
        <div className={styles.icon}>
          <div className="material-icons">
            {expanded ? "keyboard_arrow_down" : "keyboard_arrow_right"}
          </div>
        </div>
        <div>{props.title}</div>
        <div className={styles.result}>{props.result}</div>
      </div>
      {expanded && <div className={styles.children}>{props.children}</div>}
    </div>
  );
}

export default Expander;
